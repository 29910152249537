const hyphenToPascalCase = (val) => {
    const pascal = val
        .split('-')
        .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1)
        })
        .join('')

    // if first character is a number, prefix with 'Icon'
    if (pascal.charAt(0).match(/[0-9]/)) {
        return `Icon${pascal}`
    }
    return pascal
}

export { hyphenToPascalCase }
