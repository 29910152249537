import Link from 'next/link'

export const LinkController = ({ href, ...props }) => {
    // Navigation Link component should be passed in as a prop, or configured once
    const relativeUrl = href?.startsWith('/')
    const Component = relativeUrl ? Link : 'a'

    return (
        <Component
            href={href}
            target={relativeUrl ? undefined : '_blank'}
            rel={relativeUrl ? undefined : 'noreferrer'}
            {...props}
        />
    )
}
