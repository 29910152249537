import * as icons from 'react-bootstrap-icons'

export interface Props extends icons.IconProps {
    icon: keyof typeof icons
}

export const Icon = ({ icon, ...props }: Props) => {
    const BootstrapIcon = icons[icon]
    return <BootstrapIcon {...props} />
}
