import { useState, FC, ReactNode } from 'react'
import { usePopper } from 'react-popper'
import { Placement } from '@popperjs/core'
import cx from 'classnames'
import { useEventListener } from 'usehooks-ts'

import styles from './style.module.scss'

const ESCAPE_KEYS = new Set(['27', 'Escape'])

type Props = {
    trigger: ReactNode
    children: ReactNode
    placement?: Placement
    removeUnderline?: boolean
    disableClick?: boolean
    offset?: number
}

export const Tooltip: FC<Props> = ({
    trigger,
    children,
    placement,
    removeUnderline,
    disableClick,
    offset,
}) => {
    const [hover, setHover] = useState(false)
    const [clicked, setClicked] = useState(false)

    const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLSpanElement | null>(null)

    const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, offset || 6],
                },
            },
        ],
    })

    useEventListener('click', (e) => {
        // @ts-ignore
        if (referenceElement && !referenceElement.contains(e.target)) {
            setClicked(false)
        }
    })
    useEventListener('keydown', ({ key }) => {
        if (ESCAPE_KEYS.has(key)) setClicked(false)
    })

    return (
        <span className={styles.wrapper}>
            <span
                className={cx(
                    disableClick ? undefined : 'pointer',
                    removeUnderline ? undefined : styles.title
                )}
                ref={setReferenceElement}
                onClick={() => {
                    if (disableClick) return
                    setClicked(!clicked)
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {trigger}
            </span>
            {clicked || hover ? (
                <span
                    className={cx(styles.content, 'small', 'inset-sm')}
                    style={popperStyles.popper}
                    ref={setPopperElement}
                    {...attributes.popper}
                >
                    {children}
                </span>
            ) : null}
        </span>
    )
}
