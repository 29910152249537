import { Banner } from 'ds/components/Banner'
// import { Button } from 'ds/components/Button'

export const ErrorBanner = ({ children }) => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <Banner variant="danger" className="stacked-md">
                        {children}
                    </Banner>
                </div>
            </div>
        </div>
    )
}
