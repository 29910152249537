import { useCallback, useEffect } from 'react'
import { useTernaryDarkMode } from 'usehooks-ts'

const setColorScheme = () => {
    let theme = 'light' //default to light

    //local storage is used to override OS theme settings
    const localTheme = localStorage.getItem('theme') // usehooks-ts stores theme as a JSON string

    if (localTheme === 'dark' || localTheme === '"dark"') theme = 'dark'
    else if (localTheme === 'light' || localTheme === '"light"') theme = 'light'
    else if (window.matchMedia?.('(prefers-color-scheme: dark)').matches) {
        //OS theme setting detected as dark
        theme = 'dark'
    }

    //dark theme preferred, set document with a `data-theme` attribute
    if (theme === 'dark') {
        document.documentElement.setAttribute('data-theme', 'dark')
    } else {
        document.documentElement.removeAttribute('data-theme')
    }
}

export const useCheckDarkModePreference = () => {
    useEffect(() => {
        setColorScheme()

        if (!window.matchMedia) return
        window
            .matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', (e) => e.matches && setColorScheme())
        window
            .matchMedia('(prefers-color-scheme: light)')
            .addEventListener('change', (e) => e.matches && setColorScheme())
    }, [])
}

export const useColorTheme = () => {
    const { isDarkMode, ternaryDarkMode, setTernaryDarkMode } =
        useTernaryDarkMode({ localStorageKey: 'theme' })

    const setTheme = useCallback(
        (mode: 'system' | 'light' | 'dark') => {
            setTernaryDarkMode(mode)
            setColorScheme()
        },
        [setTernaryDarkMode]
    )

    return { isDarkMode, ternaryDarkMode, setTheme }
}
