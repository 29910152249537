import { Button } from 'ds/components/Button'
import React from 'react'
import { ErrorBanner } from '../ErrorBanner'

class ErrorBoundary extends React.Component {
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI
        return { hasError: true }
    }
    constructor(props) {
        super(props)
        // Define a state variable to track whether is an error or not
        this.state = { hasError: false }
    }
    componentDidCatch(error, errorInfo) {
        // You can use your own error logging service here
        console.log({ error, errorInfo })
        window.NREUM?.noticeError(error)
    }
    render() {
        // Check if the error is thrown
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="padding-top-lg">
                    <div className="stacked-md">
                        <ErrorBanner>
                            <strong>Hubo un error.</strong>
                            <div>Reportarlo a soporte@savio.mx.</div>
                        </ErrorBanner>
                    </div>
                    <div className="container-fluid">
                        <Button href="/">Dashboard</Button>
                    </div>
                </div>
            )
        }

        // Return children components in case of no error

        return this.props.children
    }
}

export default ErrorBoundary
