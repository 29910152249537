import { FC } from 'react'
import cx from 'classnames'

import { Card, CardVariant } from 'ds/components/Card'
import styles from './style.module.scss'

type Props = {
    variant: CardVariant
    children: React.ReactNode
    className?: string
}

export const Banner: FC<Props> = ({ className, variant, children }) => {
    return (
        <Card
            noInset
            className={cx(className, styles.banner)}
            variant={variant}
        >
            <div className={cx(styles.callout, styles[variant])} />
            <div className="inset-md">{children}</div>
        </Card>
    )
}
